import React from "react";

const SearchIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.625 16.625C13.491 16.625 16.625 13.491 16.625 9.625C16.625 5.75901 13.491 2.625 9.625 2.625C5.75901 2.625 2.625 5.75901 2.625 9.625C2.625 13.491 5.75901 16.625 9.625 16.625Z"
      stroke="#20222B"
      stroke-width="2"
    />
    <path
      d="M18.3756 18.3753L14.5693 14.5691"
      stroke="#20222B"
      stroke-width="2"
    />
  </svg>
);

export default SearchIcon;
