/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";

import { connectSearchBox } from "react-instantsearch-dom";
import SearchIcon from "@components/icons/search";
import ClearIcon from "@components/icons/cross";
import ArrowIcon from "@components/icons/arrowRight";
import { rem } from "@src/theme";
import { navigate } from "gatsby";
interface ComponentProps {
  currentRefinement?: string;
  refine?: (query) => void;
  submitMode: boolean;
}
export const SearchBox = ({
  currentRefinement,
  refine,
  submitMode,
}: ComponentProps) => {
  const [staticValue, setStaticValue] = useState("");
  const onInputChange = (val) => {
    if (submitMode) {
      setStaticValue(val);
    } else {
      refine(val);
    }
  };
  return (
    <form
      noValidate
      action=""
      role={submitMode ? "form" : "search"}
      sx={{
        border: "2px solid #20222B",
        position: "relative",
        mt: [rem(20), rem(20), rem(40)],
        height: [rem(49), rem(54), rem(61)],
        width: ["100%", rem(460), rem(460)],
        bg: "white",
        display: "flex",
        alignItems: "center",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        if (submitMode) {
          navigate(`/explore/search?query=${encodeURI(staticValue)}`);
        }
      }}
    >
      <span
        sx={{
          transform: ["translate(10px, 1px)", null, "translate(15px, 0px)"],
        }}
      >
        <SearchIcon />
      </span>
      <input
        placeholder="Research, insights, topics"
        sx={{
          pl: [rem(16), null, rem(22)],
          fontSize: [rem(16), null, rem(18)],
          appearance: "none",
          "-moz-appearance": ["none"],
          "-webkit-appearance": ["none"],
          height: "100%",
          border: "none",
          flex: 1,
          fontWeight: 600,
          letterSpacing: rem(-0.5),
          "&::placeholder": {
            color: "csiMidGrey",
          },
          "&:focus": {
            outline: "none",
          },
        }}
        value={currentRefinement}
        onChange={(event) => onInputChange(event.currentTarget.value)}
      />
      {currentRefinement ? (
        <button
          type="button"
          sx={{
            pr: [rem(12), null, rem(15)],
            transform: ["scale(1.3)", null, "scale(1.5)"],
          }}
          onClick={(e) => {
            e.preventDefault();
            refine("");
          }}
        >
          <ClearIcon />
        </button>
      ) : submitMode ? (
        <button
          type="submit"
          sx={{
            transform: ["translateY(2px)", null, "none"],
            mr: [rem(12), null, rem(15)],
            "@media screen only and (max-width: 321px)": {
              display: "none",
            },
          }}
        >
          <ArrowIcon />
        </button>
      ) : null}
    </form>
  );
};

// 2. Connect the component using the connector
const CustomSearchBox = connectSearchBox(SearchBox);

// 3. Use your connected widget
export default CustomSearchBox;
