import React from "react";

const CrossIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 4.5L4 12.5" stroke="#222122" stroke-width="1.33333" />
    <path d="M4 4.5L12 12.5" stroke="#222122" stroke-width="1.33333" />
  </svg>
);

export default CrossIcon;
